import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import Org from "../../components/approach/approach-organisation";
import ComingSoon from "../Work/Coming-soon";
const Organisation = () => {
  return (
    <Layout pageTitle="Organisation">
      <HeaderOne />
      <StickyHeader />
      <section className="mhh-bg-image">
      <Org/>
      <ComingSoon />
      </section>
      <Footer />
    </Layout>
  );
};
export default Organisation