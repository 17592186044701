import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import i18n from "i18n-js";
const WhatCard = (props) => {
  return (
    <Container fluid>
      <Row noGutters className="justify-content-center">
        <Col xs={12} sm={12} md={8} lg={8} xl={8} id="what-card">
          <div>
            <h1 className="text-center">{props.heading}</h1>
            <div className="what-card-content">
              <p dangerouslySetInnerHTML={{ __html:props.mhtext1}} className= {props.textclass===true ? '':'mhh-card-p'}></p>
             { props.mhtext2 && <p dangerouslySetInnerHTML={{ __html:props.mhtext2}} className= {props.textclass ? '':'mhh-card-p'}></p>}
             { props.mhtext3 && <p dangerouslySetInnerHTML={{ __html:props.mhtext3}} className= {props.textclass ? '':'mhh-card-p'}></p>}
              </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default WhatCard;
